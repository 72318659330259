import { User } from "./user";

export class UserFactory {

  public static createUser(userVM: any): User {
    const me: User = new User({
      id: userVM.Id,
      userName: userVM.Name,
      isMentor: userVM.IsAdmin,
      isClocked: userVM.IsClocked,
      avatar: 'https://st2.depositphotos.com/3557671/11719/v/950/depositphotos_117192208-stock-illustration-business-woman-icon-flat-single.jpg',
      email: userVM.Email,
      lastActivity: userVM.LastActivity,
      status: userVM.Status,
      name: userVM.Name,
      chatColor: userVM.ChatColor,
      logo: userVM.ChatLogo,
      role:userVM.Role,
      atContactPhone: userVM.AtContactPhone,
      atContactMobilePhone: userVM.AtContactMobilePhone,
      atContactAlternatePhone: userVM.AtContactAlternatePhone,
      secondaryEmail : userVM.SecondaryEmail
    });
    return me;
  }

  public static createUserLowercase(userVM: any): User {
    const me: User = new User({
      id: userVM.id,
      userName: userVM.name,
      isMentor: userVM.isAdmin,
      isClocked: userVM.isClocked,
      avatar: 'https://st2.depositphotos.com/3557671/11719/v/950/depositphotos_117192208-stock-illustration-business-woman-icon-flat-single.jpg',
      email: userVM.email,
      lastActivity: userVM.lastActivity,
      status: userVM.status,
      name: userVM.name,
      chatColor: userVM.chatColor,
      logo: userVM.chatLogo,
      role:userVM.Role,
      atContactPhone: userVM.AtContactPhone,
      atContactMobilePhone: userVM.AtContactMobilePhone,
      atContactAlternatePhone: userVM.AtContactAlternatePhone,
      secondaryEmail : userVM.SecondaryEmail
    });
    return me;
  }

  public static createEmptyUser(): User {
    const me: User = new User({
      id: '-1',
      avatar: 'https://st2.depositphotos.com/3557671/11719/v/950/depositphotos_117192208-stock-illustration-business-woman-icon-flat-single.jpg',
      logo: 'assets/images/logo.png',
      chatColor: '#4d5198'
    });
    return me;
  }

  public static createOffice365User(id: any, uname: any, email: any): User {
    const me: User = new User({
      id: id,
      userName: uname,
      email: email,
      avatar: 'https://st2.depositphotos.com/3557671/11719/v/950/depositphotos_117192208-stock-illustration-business-woman-icon-flat-single.jpg',
      logo: 'assets/images/logo.png',
      chatColor: '#4d5198'
    });
    return me;
  }
}
