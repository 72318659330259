import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../model/user';
import { ChatService } from '../../service/chatservice';

@Component({
  selector: 'chat-room-member',
  templateUrl: './chat-room-member.component.html',
  styleUrls: ['./chat-room-member.component.css']
})
export class ChatRoomMemberComponent implements OnInit {  
  @Input() thisUser: User;
  @Input() roomId:string;
  isMentor: boolean = false;
  isClocked: boolean = false;

  constructor(public chatService: ChatService) {
  }

  ngOnInit() {
    this.isMentor = this.thisUser.isMentor;
    this.isClocked = this.thisUser.isClocked;
    var test=this.roomId;
 
  }

  onClickGetUser(event: any){
    this.chatService.getUserInfo(this.thisUser.id);
  }
}
