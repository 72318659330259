import { uuid } from "../home/util/uuid";


/**
 * A User represents an agent that sends messages
 */
export class User {
  id: string;
  userName: string;
  password: string;
  firstName: string;
  lastName: string;
  avatarSrc: string;
  isMentor: boolean;
  isClocked: boolean;
  status: string;
  email: string;
  b1number: string;
  lastActivity: any;
  name: string;
  issue: string;
  subissue: string;
  message: string;
  voucher: string;
  userType: string;
  phone: string;
  address: string;
  chatColor:string;
  logo:string;
  role:string;
  atContactPhone: string;
  atContactMobilePhone: string;
  atContactAlternatePhone: string;
  secondaryEmail: string;

  constructor(obj?: any) {
    this.id = obj && obj.id || '-1';
    this.userName = obj && obj.userName || null;
    this.avatarSrc = obj && obj.avatar || null;
    this.isMentor = obj && obj.isMentor || false;
    this.isClocked = obj && obj.isClocked || false;
    this.status = obj && obj.status || '';
    this.email = obj && obj.email || '';
    this.b1number = obj && obj.b1number || null;
    this.lastActivity = obj && obj.lastActivity || '';
    this.firstName = obj && obj.firstName || null;
    this.lastName = obj && obj.lastName || null;
    this.name = obj && obj.name || null;
    this.issue = obj && obj.issue || null;
    this.subissue = obj && obj.subissue || null;
    this.message = obj && obj.message || null;
    this.voucher = obj && obj.voucher || null;
    this.chatColor = obj && obj.chatColor || '#4d5198';
    this.role = obj && obj.role || '-1';
    this.logo = obj && obj.logo || 'assets/images/logo.png';
    this.atContactPhone = obj && obj.atContactPhone || null;
    this.atContactMobilePhone = obj && obj.atContactMobilePhone || null;
    this.atContactAlternatePhone = obj && obj.atContactAlternatePhone || null;
    this.secondaryEmail = obj && obj.secondaryEmail || '';
  }
}