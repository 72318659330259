<ngx-spinner
 [zIndex]="9999" 
 type="ball-clip-rotate"
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  > </ngx-spinner>

<div *ngIf="noticeMessage!==''; else templatePopup">

  <div class="wait-wrapper" style="left: 10px;">
    <div id="wait" style="width:100%;position:absolute;left:0;right:0;text-align:center;padding:2px;">
      <img src={{noticeImage}} width="64" height="64" />      
      <p *ngIf="!showSuccessMessage">{{noticeMessage}}</p>
     
      <!-- <p *ngIf="showSuccessMessage&&isNhdClosed" >NHD ticket was successfully closed!</p> -->
      <p *ngIf="showSuccessMessage&&isNhdClosed" >NHD ticket <a   (click)="openTicket($event)" 
        class="div-link">{{ticketNumberPresent}}</a> was successfully closed!</p>
      
      <p *ngIf="showSuccessMessage&&!isTicketCreated&&ticketNumberArray.length>1" >Chat tickets <a  *ngFor="let ticketNumber of ticketNumberArray; let i = index" (click)="openTicket($event)" 
        class="div-link">{{ticketNumber}}{{i === ticketNumberArray.length -1 ? '' : ',&nbsp;' }}</a> were successfully updated!</p>
        <p *ngIf="showSuccessMessage&&!isTicketCreated&&ticketNumberArray.length===1" >Chat ticket <a  *ngFor="let ticketNumber of ticketNumberArray; let i = index" (click)="openTicket($event)" 
          class="div-link">{{ticketNumber}}</a> was successfully updated!</p>

      <p *ngIf="showSuccessMessage&&isTicketCreated&&ticketNumberArray.length>1" >Chat tickets <a  *ngFor="let ticketNumber of ticketNumberArray; let i = index" (click)="openTicket($event)" 
        class="div-link">{{ticketNumber}}{{i === ticketNumberArray.length -1 ? '' : ',&nbsp;' }}</a> were successfully created!</p>
        <p *ngIf="showSuccessMessage&&isTicketCreated&&ticketNumberArray.length===1" >Chat ticket <a  *ngFor="let ticketNumber of ticketNumberArray; let i = index" (click)="openTicket($event)" 
          class="div-link">{{ticketNumber}}</a> was successfully created!</p>
      
    </div>
  </div>
</div>


<ng-template #templatePopup>
  <div style="height: calc(100vh - 70px); background:white; display: table-cell;">
    <div id="popup-header" *ngIf="currentTicket && !currentTicket.IsPreviousTicket">
      Ticket Number:
      <a (click)="openTicket($event)" class="div-link">{{ticketNumberPresent}}</a>
    </div>
    <div id="popup-header" *ngIf="currentTicket && currentTicket.IsPreviousTicket">
      End user indicated that this chat session is regarding ChatID #{{currentTicket.PreviousRoomKey}} (GMS Ticket Number <a (click)="openTicket($event)" class="div-link">{{ticketNumberPresent}}</a>). <br/>
      Please confirm this by clicking on "CONFIRM EXISTING SESSION" or continue to closing this session and create brand new Autotask ticket.
    </div>

    <div id="popup-contents">
      <form name="form" [formGroup]="myForm" id="chatPopup" (ngSubmit)="updateTicket(myForm.value)" >
        <div id="chat-info" class="col-md-8 col-sm-8" style="background: white; margin-top: 20px;">

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="Ticket Title">Ticket Title</label>
                <textarea rows="1" [(ngModel)]="ticketTitle" [ngModelOptions]="{standalone: true}" name="tcktTitle" class="form-control TicketTitle" (blur)="ticketTitle = $event.target.value" placeholder="Ticket Title"
                  required></textarea>
                <div class="validation-errors" *ngIf="ticketTitle === '' && isValidFormSubmitted == false">
                  Ticket Title is required
                </div>
              </div>
            </div>

            <div class="col-sm-12">        
              <div class="form-group">
                <label for="template">Speed Code</label>
                <select name="ddlTemplate" (change)="onTemplateSelect($event.target.value)" (focus)="hasFocus = true" class="form-control SpeedCode1" style="width: 48% !important;">
                  <option value="0" [selected]="this.selectedTemplate == 0">Select Template</option>
                  <option value="1" [selected]="this.selectedTemplate == 1">Tech Work</option>
                  <option value="2" [selected]="this.selectedTemplate == 2">NHD - NHD</option>
                  <!-- <option value="4" [selected]="this.selectedTemplate == 4">SAH - Scheduled After Hours</option>
                  <option value="5" [selected]="this.selectedTemplate == 5">PEA - Pending Escalation Approval</option> -->
                  <option value="6" [selected]="this.selectedTemplate == 3">Non Talker Message</option>
                  <option value="7" [selected]="this.selectedTemplate == 7">Test Message</option>
                </select>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label for="issue">Child Client</label>
                <select class="form-control" [attr.disabled]="!isMspAuth ? '' : null" (change)="onChildClientSelect($event.target.value)">
                  <option value="0">-- Select Child Client --</option>
                  <option *ngFor="let childClient of childClients" value={{childClient.id}} [selected]="childClient.id === selectedChildClient">
                    {{childClient.name}}
                  </option>
                </select>
                <img *ngIf="loadingChildClients" src="assets/images/ajax-loader.gif" />
                <div class="validation-errors" *ngIf="selectedChildClient == -1 && isValidFormSubmitted == false">
                  Child client is required
                </div>
              </div>

              <div class="form-group">
                <label for="issue">Status</label>
                <select class="form-control selectstatus1" (change)="onStatusSelect($event.target.value)">
                  <option value="0">-- Select Status --</option>
                  <option *ngFor="let status of statuses" value={{status.fieldValue}} [selected]="status.fieldValue === selectedStatus">
                    {{status.fieldName}}
                  </option>
                </select>
                <div class="validation-errors" *ngIf="selectedStatus == -1 && isValidFormSubmitted == false">
                  Status is required
                </div>
              </div>

              <div class="form-group">
                <label for="issue">Issue</label>
                <select class="form-control selectissue1" (change)="onIssueSelect($event.target.value,0)">
                  <option value="0">-- Select Issue --</option>
                  <option *ngFor="let issue of issues" value={{issue.fieldValue}} [selected]="issue.fieldValue === selectedIssue">
                    {{issue.fieldName}}
                  </option>
                </select>
              </div>

            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="contact">Contact <span id="spAddNewContacts" style="color:red; font-size:11px;">{{addContactsNote}}</span> </label>

                <select class="form-control" (change)="onContactSelect($event.target.value)">
                  <option value="0">-- Select Contact --</option>
                  <option value="1" [ngClass]="{ 'hidden': hideChatTicketUpdateBtn || disableUpdate}">New Contact</option>
                  <option *ngFor="let contact of contacts" value={{contact.id}} [selected]="contact.id === selectedContact">
                    {{contact.name}}
                  </option>
                </select>


                <img *ngIf="loadingContacts" src="assets/images/ajax-loader.gif" />
                <div class="validation-errors" *ngIf="selectedContact == -1 && isValidFormSubmitted == false">
                    Contact is required
                  </div>
              </div>

            
              <!-- <div class="form-group">
                  <label for="issue">Queue</label>
                  <select class="form-control SelectQueue1" (change)="onQueueSelect($event.target.value)">
                    <option value="0">-- Select Queue --</option>
                    <option *ngFor="let queue of queues" value={{queue.statusID}} [selected]="queue.statusID === selectedQueue">{{queue.statusName}}</option>
                  </select>
                  <div class="validation-errors" *ngIf="selectedQueue == -1 && isValidFormSubmitted == false">
                      Queue is required
                  </div>
              </div> -->

              <div class="form-group">
                <label for="issue">Priority</label>
                <select class="form-control" (change)="onPrioritySelect($event.target.value)">
                  <option *ngFor="let pri of priorities" value={{pri.value}} [selected]="pri.value === selectedPriority">{{pri.name}}</option>
                </select>
            </div>


              <div class="form-group" *ngIf="showSelected">
                <label for="subissue">Subissue</label>

                <select class="form-control selectsubissue1" (change)="onSubIssueSelect($event.target.value)">                  
                  <option *ngFor="let subissue of subissues" value={{subissue.fieldValue}} [selected]="subissue.fieldValue === selectedSubIssue">{{subissue.fieldName}}</option>
                </select>
              </div>


              <div class="form-group" *ngIf="showIfTTI">
                  <label for="company">Company</label>
  
                  <select class="form-control" (change)="onCompanySelect($event.target.value)">
                    <option value="0">-- Select Company --</option>
                    <option *ngFor="let comp of companies" value={{comp.id}} [selected]="0">{{comp.accountName}}</option>
                  </select>
                  
                </div>
  

            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group private-note">
                <label for="Private Note">Mentor Internal Note</label>
                <textarea rows="4" (blur)="privateNote = $event.target.value" class="form-control" name="privatenote"
                [(ngModel)]="privateNote" [ngModelOptions]="{standalone: true}" placeholder="This note will be added as a Mentor Internal Note to the ticket"></textarea>
              </div>

              <div class="form-group internal-note">
                <label for="Internal Note">Mentor Time Entry</label>
                <textarea [(ngModel)]="internalNote" [ngModelOptions]="{standalone: true}"   rows="4" class="form-control" name="intnote"  placeholder="This note will be added as an Mentor Time Entry to the ticket"></textarea>
                <div class="validation-errors" *ngIf="internalNote === '' && isValidFormSubmitted == false">
                  Mentor Time Entry is required
              </div>
              </div>
                       
            </div>

          </div>
          <div
          formArrayName="items">
          <div
          *ngFor="let item of myForm.get('items')?.controls; let i = index;" [formGroup]="item" [formGroupName]="i" >
          <div class="row additionalTicket">
            <span class="input-group-btn close-btn col-md-2 col-sm-2 col-lg-2">
              <button data-tooltip="Close" alt="Close" class="btn-close endchatbtn glyphicon glyphicon-remove btnCloseAdditionalTicketPopup" (click)="onCloseAdditionalTicket(i)">
              </button>
            </span>
            <h4 class="titleAdditionalTicket">Ticket #{{increaseByOne(i)}}</h4>
            <div class="col-sm-12">
              <div class="form-group">
                <label for="Ticket Title">Ticket Title</label>
                <textarea rows="1"  formControlName="additionalTicketTitle"  class="form-control TicketTitle"  placeholder="Ticket Title"
                  required></textarea>
                  <div class="validation-errors">
                    <div class="error-message" *ngIf="(item.get('additionalTicketTitle').value === '' && isValidFormSubmitted == false)
                    || item.get('additionalTicketTitle').hasError('required') && (item.get('additionalTicketTitle').dirty || item.get('additionalTicketTitle').touched)">Ticket Title is required!</div>
                  </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label for="issue">Issue</label>
                <select class="form-control selectissue1" formControlName="additionalIssue" (change)="onAdditionalIssueSelect($event.target.value,i)" >
                  <option value="0">-- Select Issue --</option>
                  <option *ngFor="let issue of issues" value={{issue.fieldValue}} >
                    {{issue.fieldName}}
                  </option>
                </select>
                <!-- <div class="validation-errors">
                  <div class="error-message" *ngIf="item.get('additionalIssue').hasError('required') && (item.get('additionalIssue').dirty || item.get('additionalIssue').touched)">Ticket Title is required!</div>
                </div> -->
              </div>
            </div>

            <div class="col-sm-6" id="subissue{{increaseByOne(i)}}"  *ngIf="subIssueVisibleList[i]">
              <div class="form-group" >
                <label for="subissue">Subissue</label>

                <select class="form-control selectsubissue1" formControlName="additionalSubIssue" >                  
                  <option *ngFor="let subissue of subissuesList[i].mappingFields" value={{subissue.fieldValue}}>{{subissue.fieldName}}</option>
                </select>
                <!-- <div class="validation-errors">
                  <div class="error-message" *ngIf="item.get('additionalSubIssue').hasError('required') && (item.get('additionalSubIssue').dirty || item.get('additionalSubIssue').touched)">Ticket Title is required!</div>
                </div> -->
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group private-note">
                <label for="Private Note">Mentor Internal Note</label>
                <textarea rows="4"  class="form-control" 
                formControlName="ticketMentorPrivateNote"  placeholder="This note will be added as a Mentor Internal Note to the ticket"></textarea>
              </div>

              <div class="form-group internal-note">
                <label for="Internal Note">Mentor Time Entry</label>
                <textarea formControlName="ticketMentorTimeEntry"   rows="4" class="form-control"   placeholder="This note will be added as an Mentor Time Entry to the ticket"></textarea>
                <div class="validation-errors">
                  <div class="error-message" *ngIf="(item.get('ticketMentorTimeEntry').value === '' && isValidFormSubmitted == false) || item.get('ticketMentorTimeEntry').hasError('required') && (item.get('ticketMentorTimeEntry').dirty || item.get('ticketMentorTimeEntry').touched)">Mentor Time Entry is required.</div>
                </div>
              </div>
                      
            </div>
            <div class="col-sm-12 lblAdditionalTicketInfo">
              <p>Ticket(s) will be created in Autotask as soon as you click on "Update Ticket & Close Chat Session"</p>
            </div>
            </div>
          </div>
      </div>

        <button type="button" class="btn-chat btnAddAdditionalTicket" (click)="addItem()">Add Additional Ticket(s)</button>

        </div>

        <div id="chat-transcript" class="col-md-4 col-sm-4" style="background: white; margin-top: 20px;">

          <div class="form-group" *ngIf="currentTicket && currentTicket.IsPreviousTicket && !isPreviousTicketBinded">
            <button type="button" id="submit-1" class="btn btn-prevchat" [disabled]="disablePreviousTicketUpdate" (click)="loadPreviousTicket()" style="margin-top: 23px;" >CONFIRM EXISTING CHAT SESSION</button>
          </div>

          <div class="form-group" *ngIf="currentTicket && currentTicket.IsPreviousTicket && isPreviousTicketBinded">
            <button type="button" id="submit-1" class="btn btn-prevchat" (click)="revertToNewTicket()" >REVERT CHANGES</button>
          </div>

          <div class="form-group" >
            <button type="button"  class="btn btn-prevchat" (click)="closeNhdChat()" >FORCE CLOSE CHAT</button>
          </div>

          <div class="panel-body msg-container-base" >
            <label for="transcript" style="color: #637589">Chat Transcript</label>
            <chat-message-transcript *ngFor="let message of messagesInCurrentRoom" [message]="message">
            </chat-message-transcript>
           
          </div>
          <div class="form-group" [ngClass]="{ 'hidden': hideChatTicketUpdateBtn}" *ngIf="!(currentTicket && currentTicket.IsPreviousTicket)">
            <button type="submit" id="submit-1" class="btn-chat" *ngIf="!isMspAuth" [disabled]="disableUpdate">Update Ticket & Close Chat Session</button>
            <button type="submit" id="submit-2" class="btn-chat" *ngIf="isMspAuth" [disabled]="disableUpdate">Create Ticket & Close Chat Session</button>
            <p *ngIf="disableUpdate" class="at-down-message"> Autotask is currently down. Please try again later.</p>
          </div>
          <div class="form-group" [ngClass]="{ 'hidden': hideChatTicketUpdateBtn}" *ngIf="(currentTicket && currentTicket.IsPreviousTicket)">
            <button type="submit" id="submit-previous-1" class="btn-chat" *ngIf="isPreviousTicketBinded" [disabled]="disableUpdate">Update Ticket & Close Chat Session</button>
            <button type="submit" id="submit-previous-2" class="btn-chat" *ngIf="!isPreviousTicketBinded" [disabled]="disableUpdate">Create Ticket & Close Chat Session</button>
            <p *ngIf="disableUpdate" class="at-down-message"> Autotask is currently down. Please try again later.</p>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
