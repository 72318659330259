<ngx-spinner [timeout]="60000" type="ball-clip-rotate"> </ngx-spinner>
<div class="member-info" [ngClass]="{ 'hidden': !hideInfo }">
  <div class="parent-client-info">
    <h5>Client Info</h5> 
        <p class="user-info-name" *ngIf="userInfo" >Partner Name: {{userInfo.client.name}}</p>
        <p *ngIf="userInfo" >Partner V5 Status: {{userInfo.activity}}</p>
  </div>
  <hr>
  <div class="parent-client-info">
    <h5>User Info</h5>
      <p class="user-info-info" *ngIf="userInfo">Name: {{userInfo.user.userName}}</p>
      <p class="user-mail" *ngIf="userInfo"> <span style="color:#333333 !important;"> Primary Email Address: </span> {{userInfo.user.email}}</p>   
      <p class="user-mail" *ngIf="userInfo && userInfo.user.secondaryEmail"> <span style="color:#333333 !important;"> Secondary Email Address: </span> {{userInfo.user.secondaryEmail}}</p>   
      <p *ngIf="userInfo && userInfo.user.atContactPhone">Phone: {{userInfo.user.atContactPhone}}</p>
      <p *ngIf="userInfo && userInfo.user.atContactMobilePhone">Mobile Phone: {{userInfo.user.atContactMobilePhone}}</p>
      <p *ngIf="userInfo && userInfo.user.atContactAlternatePhone">Alternate Phone: {{userInfo.user.atContactAlternatePhone}}</p>
      <p *ngIf="userInfo">Last online: {{userInfo.user.lastActivity | date: 'medium'}}</p>
      <div class="quick-links" *ngIf="userInfo && !userInfo.user.isMentor">
        <div class="quick-links-content" style="display: inline-block !important;">
            <a class="quick-links-btn" (click)="onClickGetAllTickets($event)" style="color: white; width: 200px;">Load Previous Sessions</a>
        </div>
      </div>
  </div>
</div>
